.requestsWrapper {
    flex: 1;
    // background: greenyellow;
    display: flex;
    flex-direction: column;
    // align-items: center;
    border: 1px solid $primaryDarkHover;
    border-radius: 8px;
    margin-right: 8px;
    padding: 8px 16px 16px;

    // background: grey;
    // height: 100%;

    .requestsHeadingContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .requestsHeading {
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid $primaryDarkHover;
            padding: 0 8px;

            h2,
            .iconEnvelope {
                color: $blue;
                font-size: 3rem;
            }

            .iconEnvelope {
                margin-left: 16px;
            }
        }
    }

    .requestsContainer {
        flex: 1;
        // background: chocolate;
        padding: 22px 16px 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;

        // @include mobileBig {
        //     // justify-content: space-between;
        //     // justify-content: flex-start;
        //     // align-items: center;
        //     background: grey;
        // }

        .noRequests {
            flex: 1;
            height: 100%;
            // background: cornflowerblue;
            display: flex;
            justify-content: center;
            align-items: center;

            h3 {
                color: $primaryDark;
                font-size: 4rem;
                text-align: center;
            }
        }
    }
}
