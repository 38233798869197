.signup {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    background: $white;
    flex: 1;
    padding: 20px 40px;

    h2 {
        color: $primaryDark;
        font-size: 2.4rem;
        margin-bottom: 10px;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $blue;
        padding: 30px 30px;
        border-radius: 4px;
        box-shadow: $boxShadowPrimary;
        // min-width: 30%;
        // width: 280px;

        @include tablet {
            padding: 15px 15px;
        }

        div {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            position: relative;
            // background: chocolate;
            width: 100%;

            label {
                color: $yellow;
                font-size: 1.6rem;
                margin-bottom: 4px;
                padding-left: 8px;
            }

            input[type="text"],
            input[type="email"],
            input[type="password"] {
                @extend %inputs;
                @include placeholder-style($blue);

                &.error {
                    border: 1px solid $red;
                }
            }

            .eyeIcon {
                position: absolute;
                font-size: 1.6rem;
                color: $primaryDark;
                right: 10px;
                top: 33px;
                cursor: pointer;

                @include tablet {
                    top: 27px;
                    right: 5px;
                }

                @include mobileBig {
                    top: 23px;
                }
            }

            p {
                //this is error
                padding: 2px 8px 0;
                font-size: 1.2rem;
                color: $red;
                font-weight: 700;
                // background: green;
            }
        }

        input[type="submit"] {
            padding: 10px 18px;
            margin-top: 20px;
            max-width: 200px;
            background: $primaryDark;
            font-size: 1.8rem;
            font-weight: 700;
            color: $yellow;
            border: 1px solid $yellow;
            border-radius: 4px;
            cursor: pointer;

            @include tablet {
                padding: 6px 10px;
                margin-top: 10px;
            }

            @include mobileBig {
                padding: 4px 8px;
            }

            &:hover {
                background-color: $yellow;
                color: $primaryDark;
            }
        }
    }
}

.disclaimer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    color: $primaryDark;
    // text-align: center;

    p {
        text-align: center;
        padding: 0 5%;
        span {
            &:first-of-type {
                // color: $red;
                font-weight: 700;
            }

            &:nth-of-type(2) {
                color: $red;
                font-weight: 700;
            }
        }
    }
}

%inputs {
    outline: none;
    border: none;
    padding: 4px 8px;
    background: $white;
    color: $blue;
    font-size: 1.6rem;
    // border-bottom: 2px solid $blue;
    // box-shadow: $boxShadowPrimary;
    // border-top-left-radius: 4px;
    // border-top-right-radius: 4px;
    border-radius: 4px;
}
// %name {
//     border: 1px solid #ccc;
//     padding: 10px;
//     color: #333;
//   }

//   .border {
//     @extend %name;
//   }
