.dropDownContainerChangeLang {
    background: $blue;
    width: 35px;
    height: 35px;
    min-width: 35px;
    min-height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    position: relative;

    @include tablet {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        margin-left: 5px;
    }

    @include mobileBig {
        width: 22px;
        height: 22px;
        min-width: 22px;
        min-height: 22px;
    }

    &:hover {
        cursor: pointer;
        .dropDownWrapperChangeLang {
            display: block;
        }
    }

    .dropDownWrapperChangeLang {
        position: absolute;
        top: 20px;
        right: -15px;
        display: none;
        // display: block;
        // background: chocolate;
        z-index: 35;

        @include tablet {
            right: -5px;
            top: 15px;
        }

        @include mobileBig {
            top: 19px;
            right: -6px;
        }

        .dropDownChangeLang {
            // position: absolute;
            background: $blue;
            // background-color: $primaryDark;
            width: 150px;
            max-width: 150px;
            height: auto;
            // padding: 8px 16px;
            border-radius: 8px;
            margin-top: 28px;
            // border: 1px solid $white;

            @include mobileBig {
                width: 80px;
                max-width: 80px;
                margin-top: 12px;
            }

            ul {
                list-style-type: none;
                // display: flex;
                // justify-content: space-around;
                // align-items: center;
                // background: chocolate;
                flex: 1;
                // padding: 8px;

                li {
                    color: $white;
                    font-size: 1.4rem;
                    // padding: 12px 16px;
                    display: flex;
                    align-items: center;
                    // flex: 1;
                    // min-width: 0;
                    // background: chocolate;
                    // width: 100%;
                    padding: 8px 16px;

                    @include mobileBig {
                        padding: 4px 8px;
                    }

                    &:hover {
                        background: $primaryDarkHover;
                    }

                    &:first-of-type {
                        border-top-left-radius: 8px;
                        border-top-right-radius: 8px;
                    }

                    &:last-of-type {
                        border-bottom-left-radius: 8px;
                        border-bottom-right-radius: 8px;
                    }

                    img {
                        width: auto;
                        max-width: 20px;
                        // height: 10px;
                        height: auto;
                        object-fit: cover;

                        @include mobileBig {
                            max-width: 14px;
                        }
                    }

                    .langP {
                        color: $white;
                        font-size: 1.4rem;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-left: 12px;
                        // background-color: brown;
                        // text-align: left;
                    }
                }
            }
        }
    }

    .iconContainerChangeLang {
        width: 80%;
        height: 80%;
        // background: $primaryDark;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .iconGlobe {
            font-size: 2rem;
            color: $white;
        }
    }
}
