.profile {
    display: flex;
    flex: 1;
    // background: grey;
    padding: 20px 30px 30px;

    @include tablet {
        padding: 14px 20px 20px;
    }

    .profileWrapper {
        flex: 1;
        // background: greenyellow;
        display: flex;
        flex-direction: column;
        // align-items: center;
        border: 1px solid $primaryDarkHover;
        border-radius: 8px;
        // margin-right: 8px;
        padding: 8px 16px 16px;
        // max-width: 100vw;

        .profileUserData {
            display: flex;
            // flex: 1;
            justify-content: center;
            align-items: center;
            // background: blue;
            padding: 16px;
            // max-width: 100%;
            min-width: 0;
            margin-bottom: 8px;
            // height: auto;

            @include mobileBig {
                padding: 8px;
            }

            .profileUser {
                background: $greyMessage;
                // background: darkcyan;
                display: flex;
                align-items: center;
                flex: 1;
                min-width: 0;
                // width: auto;
                max-width: 330px;
                padding: 16px;
                border-radius: 16px;

                @include tablet {
                    max-width: 280px;
                    padding: 10px;
                }

                @include mobileBig {
                    max-width: 180px;
                    padding: 8px;
                    // border-radius: $borRadMob;
                }

                .iconContainer {
                    width: 80px;
                    height: 80px;
                    min-width: 80px;
                    min-height: 80px;
                    border-radius: 50%;
                    background: $blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 16px;

                    @include tablet {
                        width: 50px;
                        height: 50px;
                        min-width: 50px;
                        min-height: 50px;
                    }

                    @include mobileBig {
                        width: 36px;
                        height: 36px;
                        min-width: 36px;
                        min-height: 36px;
                        margin-right: 8px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    .icon {
                        font-size: 4.5rem;
                        color: $white;

                        @include tablet {
                            font-size: 3.2rem;
                        }

                        @include mobileBig {
                            font-size: 3.2rem;
                        }
                    }
                }

                .profileUsername {
                    font-size: 2.8rem;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    color: $primaryDark;
                }
            }

            .userContactsWrapper {
                display: flex;
                flex-direction: column;
                // align-items: center;
                justify-content: center;
                // padding: 0 16px;
                // max-width: 50%;
                // flex: 3;

                .userContacts {
                    display: flex;
                    width: 100px;
                    height: 100%;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    // background: blue;
                    // border-radius: 50%;

                    @include mobileBig {
                        width: 50px;
                    }

                    .iconContactsContainer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: $greyMessage;
                        height: 48px;
                        width: 48px;
                        border-radius: 50%;

                        @include tablet {
                            width: 38px;
                            height: 38px;
                        }

                        @include mobileBig {
                            width: 28px;
                            height: 28px;
                        }

                        .iconContacts {
                            font-size: 2.2rem;
                            color: $blue;
                        }
                    }

                    .contactsAmount {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        // background: chartreuse;
                        p {
                            font-size: 1.2rem;
                            font-weight: 700;
                            padding: 4px;
                            color: $primaryDark;
                        }
                    }
                }
            }
        }

        .changeUserImgPassWrapper {
            display: flex;
            // background: chocolate;
            padding-top: 8px;
            flex: 1;
            flex-wrap: wrap;
            // flex-direction: column;

            @include tablet {
                padding-top: 4px;
            }
            .changeUserDiv,
            .changePassDiv {
                display: flex;
                flex: 2;
                // background: cornsilk;
                margin: 0 12px 16px;
                justify-content: center;

                @include tablet {
                    margin: 0 8px 12px;
                }

                @include mobileBig {
                    margin: 0 4px 8px;
                }
                form {
                    display: flex;
                    flex-direction: column;
                    // align-items: center;
                    justify-content: space-between;
                    background: $greyMessage;
                    padding: 16px 24px;
                    border-radius: 16px;
                    border: 1px solid $primaryDarkHover;
                    // box-shadow: $boxShadowPrimary;
                    height: 230px;

                    @include tablet {
                        padding: 8px 14px;
                        height: 180px;
                    }

                    @include mobileBig {
                        height: 140px;
                    }

                    .changeUserInputContainer {
                        // background: coral;
                        display: flex;
                        flex-direction: column;
                        label {
                            font-size: 1.8rem;
                            color: $primaryDark;
                            border-bottom: 1px solid $primaryDarkHover;
                            margin-bottom: 16px;

                            @include tablet {
                                margin-bottom: 8px;
                            }
                        }
                        // input[type="text"] {
                        input {
                            // &.currentPass {
                            //     margin-bottom: 16px;

                            //     @include mobileBig {
                            //         margin-bottom: 4px;
                            //     }
                            // }
                            outline: none;
                            border: none;
                            padding: 4px 8px;
                            // background: $white;
                            border: 1px solid $primaryDarkHover;
                            color: $blue;
                            font-size: 1.6rem;
                            border-radius: 4px;
                            // margin-bottom: 8px;
                            @include placeholder-style($primaryDark);

                            // @include mobileBig {
                            //     margin-bottom: 4px;
                            // }
                        }

                        p {
                            //this is error
                            padding: 0 8px 0;
                            font-size: 1.2rem;
                            color: $red;
                            font-weight: 700;
                            // background: grey;
                        }
                    }
                    label {
                        font-size: 1.8rem;
                        color: $primaryDark;
                        border-bottom: 1px solid $primaryDarkHover;
                        margin-bottom: 16px;

                        @include tablet {
                            margin-bottom: 8px;
                        }
                    }
                    // input[type="text"] {
                    input {
                        // &.currentPass {
                        //     // margin-bottom: 16px;

                        //     // @include mobileBig {
                        //     //     margin-bottom: 4px;
                        //     // }
                        // }
                        outline: none;
                        border: none;
                        padding: 4px 8px;
                        // background: $white;
                        border: 1px solid $primaryDarkHover;
                        color: $blue;
                        font-size: 1.6rem;
                        border-radius: 4px;
                        // margin-bottom: 8px;
                        @include placeholder-style($primaryDark);

                        //     @include mobileBig {
                        //         margin-bottom: 4px;
                        //     }
                    }

                    p {
                        //this is error
                        padding: 0 8px 0;
                        font-size: 1.2rem;
                        color: $red;
                        font-weight: 700;
                        // background: grey;
                    }

                    .inputSubmitContainer {
                        // background: chocolate;
                        display: flex;
                        justify-content: center;
                        input[type="submit"] {
                            padding: 10px 18px;
                            // margin-top: 20px;
                            max-width: 200px;
                            background: $primaryDark;
                            font-size: 1.8rem;
                            font-weight: 700;
                            color: $white;
                            // border: 1px solid $white;
                            border: none;
                            border-radius: 4px;
                            cursor: pointer;

                            @include tablet {
                                padding: 6px 10px;
                                // margin-top: 10px;
                                max-width: 160px;
                            }

                            &:hover {
                                background-color: $blue;
                                // color: $primaryDark;
                                // border: 1px solid $yellow;
                            }
                        }
                    }
                }
            }

            .changeImageDiv {
                display: flex;
                flex: 2;
                // background: green;
                justify-content: center;
                form {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    background: $greyMessage;
                    padding: 16px 24px;
                    border-radius: 16px;
                    border: 1px solid $primaryDarkHover;
                    max-width: 90% !important;
                    max-height: 230px;

                    @include tablet {
                        padding: 8px 14px;
                        height: 180px;
                    }

                    @include mobileBig {
                        height: 140px;
                    }
                    label {
                        font-size: 1.8rem;
                        color: $primaryDark;
                        border-bottom: 1px solid $primaryDarkHover;
                        margin-bottom: 16px;
                        @include tablet {
                            margin-bottom: 8px;
                        }
                    }
                    input[type="file"] {
                        outline: none;
                        border: none;
                        padding: 4px 8px;
                        background: $white;
                        border: 1px solid $primaryDarkHover;
                        color: $blue;
                        max-width: 90% !important;
                        font-size: 1.6rem;
                        border-radius: 4px;
                        // margin-bottom: 8px;
                        @include placeholder-style($primaryDark);

                        // @include mobileBig {
                        //     max-width: 60% !important;
                        // }
                    }
                    input[type="submit"] {
                        padding: 10px 18px;
                        margin-top: 20px;
                        margin-bottom: 8px;
                        max-width: 200px;
                        background: $primaryDark;
                        font-size: 1.8rem;
                        font-weight: 700;
                        color: $white;
                        // border: 1px solid $white;
                        border: none;
                        border-radius: 4px;
                        cursor: pointer;

                        @include tablet {
                            padding: 6px 10px;
                            margin-top: 10px;
                            max-width: 160px;
                        }

                        @include mobileBig {
                            margin-top: 0;
                        }

                        &:hover {
                            background-color: $blue;
                            // color: $primaryDark;
                        }
                    }
                }
            }
        }
    }
}
