.errorWrapper {
    flex: 1;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    padding-top: 4%;

    .error {

        display: flex;
        // background: $red;
        align-items: center;
        color: $red;
        font-size: 7rem;
        border: 4px solid $red;
        border-radius: 50%;
        padding: 40px;
        width: 220px;
        height: 220px;

        .icon {
            margin-right: 10px;
        }
    }

    .errorMessage {

        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            font-size: 4rem;
            color: $primaryDark;
        }

        p {
            text-decoration: underline;
            color: $primaryDark;
            font-size: 2.4rem;

            &:hover {
                cursor: pointer;
                // color: $primaryDarkHover;
            }
        }
    }
}
