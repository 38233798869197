.contactItem {
    display: flex;
    align-items: center;
    margin: 8px;
    padding: 8px 8px 8px 16px;
    border-radius: 16px;
    max-width: 100%;
    background: $blue;
    position: relative;
    z-index: 1;

    @include tablet {
        padding: 6px 6px 6px 8px;
    }

    @include mobileBig {
        border-radius: $borRadMob;
    }

    &:hover {
        cursor: default;
    }

    &.displayHover {
        &:hover {
            background: $primaryDark;
            cursor: pointer;
        }
    }

    // &:hover {
    //     // background: $blue;
    //     background: $primaryDark;
    //     cursor: pointer;
    // }

    .addContactIconContainer {
        position: absolute;
        width: 14px;
        height: 14px;
        min-width: 14px;
        min-height: 14px;
        border-radius: 50%;
        // background: $greyMessage;
        background: $blueMessage;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        bottom: 35px;
        left: 10px;

        @include tablet {
            left: 0;
            bottom: 28px;
        }

        .addContactIcon {
            color: $greenIcon;
            // color: chartreuse;
            font-size: 0.8rem;
        }
    }

    .iconContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        background: $white;
        min-width: 35px;
        min-height: 35px;
        width: 35px;
        height: 35px;
        border-radius: 50%;

        @include tablet {
            min-width: 30px;
            min-height: 30px;
            width: 30px;
            height: 30px;
        }

        @include mobileBig {
            min-width: 25px;
            min-height: 25px;
            width: 25px;
            height: 25px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
        }

        .icon {
            color: $blue;
            font-size: 200%;
        }
    }

    .contactName {
        display: flex;
        // justify-content: center;
        // this is to then add text overflow on child like this
        // white-space: nowrap;
        //     overflow: hidden;
        //     text-overflow: ellipsis;
        flex: 1;
        min-width: 0; /* or some value */

        p {
            color: $white;
            font-size: 1.6rem;
            padding: 0 10px 0 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            // max-width: 160px;

            @include tablet {
                padding: 0 6px 0 6px;
            }
        }

        div {
            width: 10px;
            height: 10px;
            // this is still needed otherwise they shrink
            min-width: 10px;
            min-height: 10px;
            background: chartreuse;
            border-radius: 50%;
            position: relative;
            top: 30%;
            right: 0;

            @include tablet {
                width: 7px;
                height: 7px;
                // this is still needed otherwise they shrink
                min-width: 7px;
                min-height: 7px;
            }
        }
    }
}
